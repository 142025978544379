
import { Component, Vue } from 'vue-property-decorator';
import HelpPopup from '@/components/board/HelpPopup.vue';
import LogoutModal from '@/components/modal/LogoutModal.vue';
import CostModal from "@/components/modal/project/CostModal.vue";
import MessageIcon from "@/components/icons/common/MessageIcon.vue";
import MessageNotification from "@/components/common/MessageNotification.vue";
import UnisurveyLogo from "@/components/icons/common/UnisurveyLogo.vue";
import { SettingIcon } from "@/components/icons/common";

@Component({
  components: {
    SettingIcon,
    UnisurveyLogo,
    MessageNotification,
    MessageIcon,
    CostModal,
    HelpPopup,
    LogoutModal,
  },
})
export default class TopNav extends Vue {
  fixed: string = '';
  $refs: Vue['$refs'] & {
    topNav: HTMLFormElement;
    helpPopup: HelpPopup;
    noticePopup: HelpPopup;
  };
  modalIdName='';

  created(): void {
    this.load();
  }

  load(): void {
    if (this.$store.getters.token === null) this.logOut();
  }
  costPreview() {
    this.$bvModal.show('cost-preview-modal');
  }

  logOut(): void {
    this.$bvModal.show('logout-modal');
  }

  redirectUserSetting(): void {
    const path = '/users/profile';
    if (this.$route.path !== path) this.$router.push(path);
  }

  goMain(): void {
    const path = '/project/list';
    if (this.$route.path !== path) this.$router.push(path);
  }

  goQuestionList() {
    const path = '/board/question-list';
    if (this.$route.path !== path) this.$router.push(path);
  }

  openHelp() {
    /*this.modalIdName='도움말';*/
    const path = '/board/admin-help-list';
    if (this.$route.path !== path) {
      if (this.$store.getters.isAdmin) this.$router.push('/board/admin-help-list');
      else this.$refs.helpPopup.openHelp();
    }
  }
}
